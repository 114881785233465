import acresLogo from "@src/assets/tokens/ACRES.svg";
import apeLogo from "@src/assets/tokens/APE.png";
import axlyLogo from "@src/assets/tokens/AXLY.svg";
import baiLogo from "@src/assets/tokens/BAI.svg";
import bnbLogo from "@src/assets/tokens/BNB.svg";
import bonkLogo from "@src/assets/tokens/BONK.png";
import bomeLogo from "@src/assets/tokens/BOME.png";
import btcbLogo from "@src/assets/tokens/BTCB.svg";
import btcwxgLogo from "@src/assets/tokens/BTC-WXG.svg";
import burnxtnLogo from "@src/assets/tokens/BURN-XTN.svg";
import chillguyLogo from "@src/assets/tokens/CHILLGUY.png";
import dogeLogo from "@src/assets/tokens/DOGE.png";
import eastLogo from "@src/assets/tokens/EAST.svg";
import eggLogo from "@src/assets/tokens/EGG.svg";
import ethLogo from "@src/assets/tokens/ETH.svg";
import ethuLogo from "@src/assets/tokens/ETHU.png";
import ethwxgLogo from "@src/assets/tokens/ETH-WXG.svg";
import fomoLogo from "@src/assets/tokens/FOMO.jpeg";
import fudtLogo from "@src/assets/tokens/FUDT.svg";
import goatLogo from "@src/assets/tokens/GOAT.png";
import gxwxLogo from "@src/assets/tokens/GXWX.svg";
import l2mpLogo from "@src/assets/tokens/L2MP.svg";
import leasedwavesLogo from "@src/assets/tokens/LEASEDWAVES.svg";
import neiroLogo from "@src/assets/tokens/NEIRO.png";
import nsbtLogo from "@src/assets/tokens/NSBT.svg";
import pepeLogo from "@src/assets/tokens/PEPE.png";
import peteLogo from "@src/assets/tokens/PETE.svg";
import plethLogo from "@src/assets/tokens/PL-ETH.svg";
import plusdtLogo from "@src/assets/tokens/PL-USDT.svg";
import plwavesLogo from "@src/assets/tokens/PL-WAVES.svg";
import plutoLogo from "@src/assets/tokens/PLUTO.svg";
import popcatLogo from "@src/assets/tokens/POPCAT.png";
import powerLogo from "@src/assets/tokens/POWER.svg";
import puzzleLogo from "@src/assets/tokens/PUZZLE.svg";
import pz5poolLogo from "@src/assets/tokens/PZ-5POOL.jpeg";
import pzbbbLogo from "@src/assets/tokens/PZ-BBB.jpg";
import pzburnxtnxpptLogo from "@src/assets/tokens/PZ-BURN-XTN-X-PPT.jpg";
import pzburnxtnLogo from "@src/assets/tokens/PZ-BURN-XTN.jpg";
import pzmegapeteLogo from "@src/assets/tokens/PZ-MEGA-PETE.svg";
import pzoldgoldLogo from "@src/assets/tokens/PZ-OLD-GOLD.jpg";
import pzunitsLogo from "@src/assets/tokens/PZ-UNITS.jpg";
import pzwebLogo from "@src/assets/tokens/PZ-WEB.svg";
import romeLogo from "@src/assets/tokens/ROME.svg";
import romeKeeperLogo from "@src/assets/tokens/ROME-KEEPER.svg";
import sashaxLogo from "@src/assets/tokens/SASHA-X.svg";
import sbtLogo from "@src/assets/tokens/SBT.svg";
import signLogo from "@src/assets/tokens/SIGN.svg";
import shibLogo from "@src/assets/tokens/SHIB.png";
import snsbtLogo from "@src/assets/tokens/SNSBT.png";
import spiceLogo from "@src/assets/tokens/SPICE.svg";
import spxLogo from "@src/assets/tokens/SPX.png";
import surfLogo from "@src/assets/tokens/SURF.svg";
import swavesLogo from "@src/assets/tokens/SWAVES.svg";
import swopLogo from "@src/assets/tokens/SWOP.svg";
import titsLogo from "@src/assets/tokens/TITS.webp";
import tsnLogo from "@src/assets/tokens/TSN.svg";
import twopeteLogo from "@src/assets/tokens/2PETE.webp";
import unit0Logo from "@src/assets/tokens/UNIT0.svg";
import unknownlogoLogo from "@src/assets/tokens/UNKNOWN-LOGO.svg";
import usdcbep20Logo from "@src/assets/tokens/USDC-BEP20.svg";
import usdcerc20Logo from "@src/assets/tokens/USDC-ERC20.svg";
import usdcpolyLogo from "@src/assets/tokens/USDC-POLY.svg";
import usdcpptLogo from "@src/assets/tokens/USDC-PPT.svg";
import usdctrc20Logo from "@src/assets/tokens/USDC-TRC20.svg";
import usdcuLogo from "@src/assets/tokens/USDCU.png";
import usdcwxgLogo from "@src/assets/tokens/USDC-WXG.svg";
import usdtbep20Logo from "@src/assets/tokens/USDT-BEP20.svg";
import usdterc20Logo from "@src/assets/tokens/USDT-ERC20.svg";
import usdtpolyLogo from "@src/assets/tokens/USDT-POLY.svg";
import usdtpptLogo from "@src/assets/tokens/USDT-PPT.svg";
import usdttrc20Logo from "@src/assets/tokens/USDT-TRC20.svg";
import usdtuLogo from "@src/assets/tokens/USDTU.png";
import usdtwxgLogo from "@src/assets/tokens/USDT-WXG.svg";
import viresLogo from "@src/assets/tokens/VIRES.svg";
import vvxtnlpLogo from "@src/assets/tokens/VVXTNLP.svg";
import wavesLogo from "@src/assets/tokens/WAVES.svg";
import wavesdlpLogo from "@src/assets/tokens/WAVESDLP.svg";
import wbriacashLogo from "@src/assets/tokens/WBRIACASH.svg";
import wbriacashindexlpLogo from "@src/assets/tokens/WBRIACASH-INDEX-LP.svg";
import wbriacashlpLogo from "@src/assets/tokens/WBRIACASH-LP.svg";
import wbtcuLogo from "@src/assets/tokens/WBTCU.png";
import wbtcerc20Logo from "@src/assets/tokens/WBTC-ERC20.svg";
import westLogo from "@src/assets/tokens/WEST.svg";
import whirlpoolLogo from "@src/assets/tokens/WHIRLPOOL.jpeg";
import wifLogo from "@src/assets/tokens/WIF.png";
import windLogo from "@src/assets/tokens/WIND.png";
import wlgoldLogo from "@src/assets/tokens/WLGOLD.svg";
import wxLogo from "@src/assets/tokens/WX.svg";
import xtnLogo from "@src/assets/tokens/XTN.svg";

const tokenLogos: Record<string, string> = {
  "ACRES": acresLogo,
  "APE": apeLogo,
  "AXLY": axlyLogo,
  "BAI": baiLogo,
  "BNB": bnbLogo,
  "BONK": bonkLogo,
  "BOME": bomeLogo,
  "BTCB": btcbLogo,
  "BTC-WXG": btcwxgLogo,
  "BURN-XTN": burnxtnLogo,
  "CHILLGUY": chillguyLogo,
  "DOGE": dogeLogo,
  "EAST": eastLogo,
  "EGG": eggLogo,
  "ETH": ethLogo,
  "ETHU": ethuLogo,
  "ETH-WXG": ethwxgLogo,
  "FOMO": fomoLogo,
  "FUDT": fudtLogo,
  "GOAT": goatLogo,
  "GXWX": gxwxLogo,
  "L2MP": l2mpLogo,
  "LEASEDWAVES": leasedwavesLogo,
  "NEIRO": neiroLogo,
  "NSBT": nsbtLogo,
  "PEPE": pepeLogo,
  "PETE": peteLogo,
  "PL-ETH": plethLogo,
  "PL-USDT": plusdtLogo,
  "PL-WAVES": plwavesLogo,
  "PLUTO": plutoLogo,
  "POPCAT": popcatLogo,
  "POWER": powerLogo,
  "PUZZLE": puzzleLogo,
  "PZ-5POOL": pz5poolLogo,
  "PZ-BBB": pzbbbLogo,
  "PZ-BURN-XTN-X-PPT": pzburnxtnxpptLogo,
  "PZ-BURN-XTN": pzburnxtnLogo,
  "PZ-MEGA-PETE": pzmegapeteLogo,
  "PZ-OLD-GOLD": pzoldgoldLogo,
  "PZ-UNITS": pzunitsLogo,
  "PZ-WEB": pzwebLogo,
  "ROME": romeLogo,
  "ROME-KEEPER": romeKeeperLogo,
  "SASHA-X": sashaxLogo,
  "SBT": sbtLogo,
  "SIGN": signLogo,
  "SHIB": shibLogo,
  "SNSBT": snsbtLogo,
  "SPICE": spiceLogo,
  "SPX": spxLogo,
  "SURF": surfLogo,
  "SWAVES": swavesLogo,
  "SWOP": swopLogo,
  "TITS": titsLogo,
  "TSN": tsnLogo,
  "UNIT0": unit0Logo,
  "UNKNOWN-LOGO": unknownlogoLogo,
  "USDC-BEP20": usdcbep20Logo,
  "USDC-ERC20": usdcerc20Logo,
  "USDC-POLY": usdcpolyLogo,
  "USDC-PPT": usdcpptLogo,
  "USDC-TRC20": usdctrc20Logo,
  "USDCU": usdcuLogo,
  "USDC-WXG": usdcwxgLogo,
  "USDT-BEP20": usdtbep20Logo,
  "USDT-ERC20": usdterc20Logo,
  "USDT-POLY": usdtpolyLogo,
  "USDT-PPT": usdtpptLogo,
  "USDT-TRC20": usdttrc20Logo,
  "USDTU": usdtuLogo,
  "USDT-WXG": usdtwxgLogo,
  "VIRES": viresLogo,
  "VVXTNLP": vvxtnlpLogo,
  "WAVES": wavesLogo,
  "WAVESDLP": wavesdlpLogo,
  "WBRIACASH": wbriacashLogo,
  "WBRIACASH-INDEX-LP": wbriacashindexlpLogo,
  "WBRIACASH-LP": wbriacashlpLogo,
  "WBTCU": wbtcuLogo,
  "WBTC-ERC20": wbtcerc20Logo,
  "WEST": westLogo,
  "WHIRLPOOL": whirlpoolLogo,
  "WIF": wifLogo,
  "WIND": windLogo,
  "WLGOLD": wlgoldLogo,
  "WX": wxLogo,
  "XTN": xtnLogo,
  "2PETE": twopeteLogo,
};

export default tokenLogos;
